<template lang="pug">
  #overlay-blood-pressure-with-selfie-video
    icon.face(data="@icon/overlay/evidence-guideline-face.svg")
    icon.bp-monitor(data="@icon/overlay/selfie-camera-guideline-blood-pressure.svg")
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
